<template>
    <div style="padding: 32px 0px;border-radius: 10px;background-color: #f3f7f9;text-align: center;">
        <img class="mb-3" src="@/assets/img/global/NoData.png" alt="">
        <h3 class="mt-3 text-center" style="font-size: 16px;font-weight: 700;color: #0a3041;">
            {{ $t('There is currently no content to display!') }}
        </h3>
    </div>
</template>
<script>
export default {
 data() {
   return {
   };
 },
};
</script>